.sale-houses {
  background: url(../images/concrete_seamless.png) #f2f0f0 repeat;

  &__item {
    @extend .offers-item;
    display: flex;
    position: relative;
    width: 100%;
    &-content {
      position: relative;
    }
  }

  &__link {
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
  }

  &__img {
    display: block;
    position: relative;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  &__info {
    flex: 0 0 550px;
    width: 550px;
    flex-grow: 1;

    .offers-item__content {
      position: relative;
    }
  }

  .house {
    width: 100%;
    flex-grow: 2;
    margin: 0;
  }
}

.btn-wrap {
  text-align: center;
}

.btn_sale {
  margin: 20px 0 80px;
}

.offers-item__content .sale-houses__list {
  margin-left: 20px;
}

.offers-item__content .sale-houses__item {
  display: list-item;
  list-style-type: square;
  font-weight: 400;
  line-height: 24px;
  padding: 0;
}

.btn_sale-houses {
  background-color: $light-green;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #2d2d2d;
  padding: 15px 25px;
  margin: 40px 0;
}

.accent-block .sale-houses__item {
  color: #2d2d2d;
}

.accent-block .btn_sale-houses {
  background-color: #2d2d2df0;
  color: #efefef;
}

/**** SALE ****/

.sale {
  background: url(../images/concrete_seamless.png) #f2f0f0 repeat;

  &-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 50px;
    padding-bottom: 50px;
  }

  &__title {
    font-size: 18px;
  }

  &__item {
    display: flex;
    flex: 0 0 32%;
    margin-bottom: 40px;
    overflow: hidden;
    position: relative;

    &:hover .sale__item-img {
      transform: scale(1.1);
      transition: 1s;
    }

    &:hover figure:before {
      opacity: 0;
      transition: 0.5s;
    }

    figure {
      position: relative;
      width: 100%;

      &:before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.2) 55%,
          rgba(0, 0, 0, 0.4) 100%
        );
        z-index: 2;

        transition: 0.7s;
      }
    }

    &-img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      z-index: 1;
      transition: 0.5s;
    }

    &-title {
      position: absolute;
      bottom: 15px;
      right: 15px;
      font: 500 15px $title-font;
      color: #efefef;
      text-align: right;
      z-index: 5;
      display: flex;
      width: calc(100% - 30px);
      justify-content: space-between;
      align-items: flex-end;
    }
  }
}

.sold.sale__item:before {
  content: 'продан';
  display: block;
  box-sizing: border-box;
  line-height: 36px;
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  top: 5px;
  left: 0;
  background-color: $light-gray;
  font-size: 1.2rem;
  font-weight: 600;
  color: white;
  z-index: 4;
}

.sold.sale__item figure:before {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(250, 250, 250, 0.15)),
    color-stop(35%, rgba(250, 250, 250, 0.2)),
    to(rgba(250, 250, 250, 0.3))
  );
  background: linear-gradient(
    to bottom,
    rgba(250, 250, 250, 0.15) 0%,
    rgba(250, 250, 250, 0.2) 35%,
    rgba(250, 250, 250, 0.3) 100%
  );
}

.sold.sale__item .sale__item-img {
  filter: grayscale(0.6);
}

.constraction.offers_sale:before {
  background-image: url(../images/constraction.svg);
  background-position: -4px -5px;
}

.discount {
  &:before {
    content: '-20%';
    display: block;
    box-sizing: border-box;
    line-height: 36px;
    padding-left: 15px;
    padding-right: 15px;
    position: absolute;
    top: 5px;
    left: 0;
    background-color: #ea0606d1;
    font-size: 1.2rem;
    font-weight: 600;
    color: white;
    z-index: 4;
  }
}

.ready {
  &:before {
    content: '100% готов';
    display: block;
    box-sizing: border-box;
    line-height: 36px;
    padding-left: 15px;
    padding-right: 15px;
    position: absolute;
    top: 5px;
    left: 0;
    background-color: $green;
    font-size: 1.2rem;
    font-weight: 600;
    color: white;
    z-index: 4;
  }
}

.under-constraction {
  &:before {
    content: 'строится';
    display: block;
    box-sizing: border-box;
    line-height: 36px;
    padding-left: 15px;
    padding-right: 15px;
    position: absolute;
    top: 5px;
    left: 0;
    background-color: $green;
    font-size: 1.2rem;
    font-weight: 600;
    color: white;
    z-index: 4;
  }
}

.constraction {
  background: white;
  box-shadow: inset 0 -2px 8px 0 rgba(0, 0, 0, 0.2);
}

.sale__subtitle {
  font-size: 1.6rem;
  font-weight: 700;
  text-align: center;
  color: gray;
  font-family: 'Montserrat', sans-serif;
}

.street-map {
  max-width: 600px;
  margin: auto;

  &__img {
    margin-top: 40px;
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.sale__item-map {
  cursor: pointer;

  figure::before {
    display: none;
  }
}
