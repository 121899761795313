.building {
  width: 100%;
  height: 80vh;
  background-image: url(../images/building/main.jpg);
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  &__title {
    font: 900 3.5rem 'Montserrat', sans-serif;
    color: $white;
    width: 580px;
    margin-right: 30px;
    margin-top: 15vh;
    // background-color: rgba(255, 255, 255, 0.65);
    // border: 1px solid $green;
    padding: 30px;
    // border-radius: 30px;
    // box-shadow: 0 2px 7px 0 rgba(78, 113, 8, 0.59);
  }

  &__section-title {
    font: 800 3rem 'Montserrat', sans-serif;
    color: $dark-green;
    margin-bottom: 60px;
    text-align: center;
  }

  &__section_offer {
    padding-top: 30px;
    padding-bottom: rem(150);
  }
}

.promise_left {
  .promise__title {
    width: 60%;
    margin: 0 auto 30px;
    text-align: left;
    font-weight: 900;
  }

  &:before {
    right: 2%;
    top: 3%;
    left: auto;
  }
}

.about-materials {
  padding: 80px 30px 100px;
  background-color: $light-green;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 40' width='80' height='40'%3E%3Cpath fill='%23efefef' fill-opacity='0.4' d='M0 40a19.96 19.96 0 0 1 5.9-14.11 20.17 20.17 0 0 1 19.44-5.2A20 20 0 0 1 20.2 40H0zM65.32.75A20.02 20.02 0 0 1 40.8 25.26 20.02 20.02 0 0 1 65.32.76zM.07 0h20.1l-.08.07A20.02 20.02 0 0 1 .75 5.25 20.08 20.08 0 0 1 .07 0zm1.94 40h2.53l4.26-4.24v-9.78A17.96 17.96 0 0 0 2 40zm5.38 0h9.8a17.98 17.98 0 0 0 6.67-16.42L7.4 40zm3.43-15.42v9.17l11.62-11.59c-3.97-.5-8.08.3-11.62 2.42zm32.86-.78A18 18 0 0 0 63.85 3.63L43.68 23.8zm7.2-19.17v9.15L62.43 2.22c-3.96-.5-8.05.3-11.57 2.4zm-3.49 2.72c-4.1 4.1-5.81 9.69-5.13 15.03l6.61-6.6V6.02c-.51.41-1 .85-1.48 1.33zM17.18 0H7.42L3.64 3.78A18 18 0 0 0 17.18 0zM2.08 0c-.01.8.04 1.58.14 2.37L4.59 0H2.07z'%3E%3C/path%3E%3C/svg%3E");
}

.materials__img {
  max-width: 100%;
  height: auto;
}

.materials_item {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 30px;
  margin-top: 30px;
  background-color: #fff;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  &_photo-wrap {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: flex-start;
    text-align: center;
    padding-right: 30px;
  }

  &_text {
    width: 65%;
  }

  &-header {
    font: 600 1.5rem 'Lato', Verdana, sans-serif;
    margin-bottom: 15px;
    color: $dark-green;
  }
}

.dom__text {
  font: 1.1rem Myriad Pro, Verdana, sans-serif;
  line-height: 2rem;
}

.dom__text_header {
  font: 800 2rem Roboto, Arial, sans-serif;
  color: #cf216f;
  margin-top: 16px;
}

.materials_list_item {
  font: 100 1rem Roboto, Arial, sans-serif;
  color: #020202;
  text-align: justify;
  line-height: 20px;

  i {
    color: $green;
    padding-right: 10px;

    &.fa-leaf {
      font-size: 0.9rem;
    }

    &.fa-circle {
      font-size: 0.5rem;
      padding-right: 12px;
      padding-left: 5px;
    }
  }
}

.materials_photo {
  width: 100%;
  padding-top: 15px;
}

.small-prev {
  .house {
    width: 24%;
    background: transparent;
  }

  .offers-item {
    margin-bottom: 60px;
  }

  .offers-item__content {
    position: relative;
    background: transparent !important;
    padding: 0;

    ul {
      padding-top: 0;
    }

    li {
      color: $gray;
      font-size: 13px;
      padding-right: 10px;
    }
  }

  .offers-title {
    color: $gray;
    font: 800 1.1rem 'Montserrat', sans-serif;
    margin-bottom: 5px;
    margin-top: 15px;
    padding-left: 3px;
  }

  .offers-item:hover .offers-item__img {
    transform: unset;
  }

  .offers-item__icon {
    width: 16px;
    height: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .small-prev .house {
    width: 32%;
  }
}

@media screen and (max-width: 800px) {
  .materials_item_photo-wrap {
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    padding-right: 0;
  }

  .materials_item {
    flex-wrap: wrap;
    padding: 30px;
  }

  .materials_item_text {
    width: 100%;
    padding-top: 40px;
  }

  .materials_photo {
    width: 40%;
  }

  .materials_list {
    padding-left: 20px;
  }
}

@media screen and (max-width: 639px) {
  .small-prev .house {
    width: 48%;
  }

  .building__title {
    font-size: 3rem;
    margin-left: 15px;
    margin-right: 15px;
    width: 100%;
  }

  .small-prev .house {
    width: 90%;
  }
}

@media screen and (max-width: 560px) {
  .building__title {
    margin-top: 55vh;
  }

  .materials_item {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: -20px;
    margin-right: -20px;
  }

  .building__title {
    font-size: 1.6rem;
  }
  .materials_item_photo-wrap {
    flex-direction: column;
  }

  .materials_photo {
    width: 80%;
  }

  .materials_list {
    width: 100%;
    padding-left: 0;
  }
  .about-materials {
    padding-left: 0px;
    padding-right: 0px;
  }
}
