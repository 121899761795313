@media (max-width: 1279px) {
  .dom-review-wrap {
    flex-wrap: wrap;
    flex-direction: column;
    background-color: #fff;

    .sale-houses__info {
      width: 100%;
    }
  }
}

@media (max-width: 1024px) {
  .sale-houses__item {
    flex-wrap: wrap;
    flex-direction: column;

    &.item-reverse {
      flex-direction: column-reverse;
    }

    .sale-houses__info {
      width: 100%;
    }
  }
}

@media (max-width: 1023px) {
  main {
    padding: 0;
  }

  .primary-navigation {
    left: -180px;
    transition: 0.4s;

    &.is-active {
      left: 0;
      transition: 0.6s;
    }
  }

  .btn.btn-menu-trigger {
    display: block;
    transition: 0.4s;
  }

  .slider-contacts-list {
    display: none;
  }

  .slider-contacts .btn-callback {
    position: fixed;
    right: 0;
    top: 0;
  }

  .sale-houses .house {
    width: 100%;
  }
}

@media (max-width: 901px) {
  .offers .cl-4 {
    width: 50%;
  }

  .offers .cl-6 {
    width: 100%;
  }

  .gallery {
    padding-right: 15px;
    padding-left: 15px;

    &_item {
      width: 50%;
    }
  }
}

@media screen and (max-width: 799px) {
  .dom-content,
  .dom-plan {
    width: 100%;
  }

  .house,
  .promo__item {
    width: 100%;
  }

  .promo-wrap {
    padding-left: 10px;
    padding-right: 10px;
  }

  .sale__item {
    flex: 0 0 48%;
  }
}

@media (max-width: 641px) {
  .offers .cl-4 {
    width: 100%;
  }

  .slider-contacts {
    display: none;
  }

  .section-title,
  .dom-page-title {
    font-size: 1.6rem;
  }

  .page-title {
    font-size: 32px;
  }

  .offers .offers-item__icon {
    width: 20px;
    height: 20px;
  }

  .offers .offers-item__content {
    padding-left: 10px;
    padding-right: 10px;
  }

  .dom-page-wrap {
    position: relative;
    margin-top: 100px;
    top: 0;
  }

  .dom-page-title {
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }

  .icon-house {
    display: none;
  }

  .dom-photo_item,
  .dom_img-plan {
    width: 100%;
  }

  .dom-photo_item.main_photo {
    width: 100%;
    margin-bottom: 20px;
  }

  .dom-content,
  .dom-plan {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .house-info {
    flex-wrap: wrap;
    position: relative;
    justify-content: center;
    bottom: -10px;
  }

  .sale__item {
    flex: 0 0 95%;
  }
}

@media (max-width: 481px) {
  .header-logo {
    width: 60px;
    padding: 5px 8px;

    &__img {
      display: none;

      &-mob {
        display: block;
      }
    }
  }

  .primary-navigation {
    left: -100%;
    width: 100%;
  }

  .gallery {
    padding-right: 0px;
    padding-left: 0px;

    &_item {
      width: 100%;
    }
  }

  .pop-up-container textarea {
    height: 140px;
  }
  .mobile-call {
    display: block;
  }

  .header-logo__img {
    display: none;

    &-mob {
      display: block;
    }
  }
}

@media (max-width: 481px) {
  .dom-description {
    padding: 50px 30px;
    font-size: 13px;
    li {
      margin-bottom: 7px;
    }
  }

  .offers-item__content {
    padding: 40px 15px;
  }

  .offers-item__content ul {
    padding: 40px 20px;
  }

  .invest_table {
    font-size: 10px;

    .red_price {
      font-size: 10px;
    }
  }

  .invest_table thead {
    font-size: 10px;
  }
}
