.header {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	font-family: $alt-font;
	font-weight: 600;
	text-transform: uppercase;
	color: $white;
	z-index: 50;	
	
	&-logo {
		display: block;
		width: 180px;
		background-color: $black;
		padding: 10px 20px 10px;

		&__img {
			width: 100%;

			&-mob {
				display: none;
				width: 60px;
				object-fit: contain;
			}
		}
	}	

	&-contacts {
		position: relative;

		&__link {
			font-family: $alt-font;
			font-size: 12px;
			line-height: 30px;
		}

		&__item {
			padding-left: 25px;
		}
	}

	.email {
		font-size: 10px;
		padding-top: 60px;
	}

	.fas {
		padding-right: 10px;
		font-size: 14px;
	}	
}

ul.fa-phone-square:before {
    content: "\f098";
    position: absolute;
    top: 8px;
    left: 0;
}

.btn.btn-menu-trigger {
	display: none;
}

.menu {	

	&__link {
		font-size: 12px;
		line-height: 35px;		
		
		&:hover {
			color: $light-green;
		}
	}
}

.social-media {
	display: flex;
	justify-content: space-around;
	text-align: center;

	&__item {
		display: inline-block;
	}
}

.primary-navigation {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1 0 180px;
	position: fixed;
	top: 0;
	left: 0;
	width: 180px;
	height: 100vh;
	min-height: 768px;
	background-color: $black;
	font-family: $alt-font;
	font-weight: 600;
	text-transform: uppercase;
	color: $white;
	padding: 150px 20px 30px;
	z-index: 45;
	transition: .7s;
}

.mobile-call {
	display: none;
	width: 100%;
	background-color: $light-green;

	@extend .header-contacts__link;
	color: $gray;
	padding: 15px 10px;
	text-align: center;
}




