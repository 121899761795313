.offers {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;

	&-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
    	padding: 0 10px;
	}

	&__subtitle {
		width: 80%;
		font-size: 1.1rem;
		line-height: 1.7em;
		text-align: center;
		padding-bottom: 15px;		
		margin: 0 auto;		
	}

	&-item {
		position: relative;		
		font-family: 'Play', sans-serif;
		color: #fff;

		&:hover .offers-item__img {
			transform: scale(1.1);
			transition: 1s;
		}

		&.house {
			overflow: hidden;
			.offers-item__content {
			    justify-content: flex-end;
			    // background-color: rgba(0, 0, 0, 0.4);
			    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0.2) 100%);
			    transition: all .8s;			    
			}

			&:hover .offers-item__content {
				background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0.2) 100%);
				transition: all .9s;
			}
		}

		

		&__content {
			display: flex;
			flex-direction: column;
			position: absolute;
			top: 0;
		    right: 0;
		    left: 0;
		    bottom: 0;
		    padding: 40px 30px 40px;
		    z-index: 2;

		    transition: .5s;

		    ul {
		    	padding-top: 30px;
		    }

		    li {
		    	display: inline-block;
		    	font: 600 16px $alt-font;
		    	color: $white;
		    	padding-right: 20px;
		    }

		    i {
		    	color: $green;
		    	font-size: 22px;
		    	line-height: 30px;
		    	padding-right: 7px;
		    }

		    p {
		    	padding: 20px 0;
		    	font-size: 20px; 
		    	line-height: 26px;
		    }
		}

		&__icon {
			display: inline-block;
			width: 30px;
			height: 30px;
			background-repeat: no-repeat;
			background-position: left bottom;
			background-size: cover;
			margin-right: 5px;
			transform: translateY(5px);
		}

		&__img {
			display: block;
			position: relative;
			width: 100%;
			z-index: 1;
			transition: .5s;
		}
	}
}


.home {
	background-image: url(./../images/home.svg);
}

.area {
	background-image: url(./../images/area.svg);
}

.bedroom {
	background-image: url(./../images/bed.svg);
}

.bathroom {
	background-image: url(./../images/bathroom.svg);
}



.cl-4 {
	width: 33.333%
}

.cl-5 {
	width: 50%;
}

.cl-6 {
	width: 66.666%;
}

.light-block {
	background-color: $white;
	color: $black;

	.offers-title {
		color: $green;
	}
}

.dark-block {
	background-color: $black;
	.offers-title {
		color: $light-green;
	}
}

.accent-block {
	background-color: $light-green;
	color: $black;

	.offers-title {
		color: $black;
	}
}

.house {
    width: 49%;
    margin-bottom: 15px;

    img {
    	width: 100%;
    	height: auto;
    	display: block;
    }
}