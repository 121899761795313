.btn {	
	@extend .slider-contacts__link;
	display: inline-block;
	border: 1px solid;
	padding: 15px 20px;
	color: $white;

  &:hover {
    color: $gray;
  }

	&-menu-trigger {
		color: $black;
		background-color: $white;
		border-color: $white;
	}


	&-callback {	
		background-color: $green;
		border-color: $green;
		padding: 28px 30px;

		&:hover {
			background-color: $light-green;
			color: $gray;
		}
	}

	&-slider {		
		border-color: $white;
		background: rgba(255,255,255,.4);
		

		&:hover {
			border-color: $green;
			background: $light-green;
			color: $gray;
		}
	}

	&-fixed {
	    display: flex;
		align-items: center;
	}
}

.btn-scroll-up {
	display: flex;
	align-items: center;
}


.fa-phone:before {
    color: #fff;
    font-size: 26px;
}


.house-animation {
  -webkit-animation: animationFrames ease-in-out 4s;
  animation: animationFrames ease-in-out 4s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  /*when the spec is finished*/
  -webkit-animation: animationFrames ease-in-out 6s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards;
  /*Chrome 16+, Safari 4+*/
  -moz-animation: animationFrames ease-in-out 4s;
  -moz-animation-iteration-count: infinite;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode: forwards;
  /*FF 5+*/
  -o-animation: animationFrames ease-in-out 4s;
  -o-animation-iteration-count: infinite;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode: forwards;
  /*Not implemented yet*/
  -ms-animation: animationFrames ease-in-out 4s;
  -ms-animation-iteration-count: infinite;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode: forwards;
  /*IE 10+*/
  overflow: hidden; }

.house-animation__button {
  -webkit-animation: btnAnimationFrames ease-in-out 2s;
  animation: btnAnimationFrames ease-in-out 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  /*when the spec is finished*/
  -webkit-animation: btnAnimationFrames ease-in-out 3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards;
  /*Chrome 16+, Safari 4+*/
  -moz-animation: btnAnimationFrames ease-in-out 2s;
  -moz-animation-iteration-count: infinite;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode: forwards;
  /*FF 5+*/
  -o-animation: btnAnimationFrames ease-in-out 2s;
  -o-animation-iteration-count: infinite;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode: forwards;
  /*Not implemented yet*/
  -ms-animation: btnAnimationFrames ease-in-out 2s;
  -ms-animation-iteration-count: infinite;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode: forwards;
  /*IE 10+*/
  overflow: hidden; }

.house-animation__button:hover {
  animation: none; }

@keyframes animationFrames {
  0% {
    -webkit-transform: scaleX(1) scaleY(1);
    transform: scaleX(1) scaleY(1); }
  50% {
    -webkit-transform: scaleX(1.05) scaleY(1.05);
    transform: scaleX(1.05) scaleY(1.05); }
  100% {
    -webkit-transform: scaleX(1) scaleY(1);
    transform: scaleX(1) scaleY(1); } }

@-webkit-keyframes animationFrames {
  0% {
    -webkit-transform: scaleX(1) scaleY(1); }
  50% {
    -webkit-transform: scaleX(1.05) scaleY(1.05); }
  100% {
    -webkit-transform: scaleX(1) scaleY(1); } }

@keyframes btnAnimationFrames {
  0% {
    -webkit-transform: scaleX(1) scaleY(1);
    transform: scaleX(1) scaleY(1);
    background-color: #d8ea7a; }
  50% {
    -webkit-transform: scaleX(1.05) scaleY(1.05);
    transform: scaleX(1.05) scaleY(1.05);
    background-color: #b1e60e; }
  100% {
    -webkit-transform: scaleX(1) scaleY(1);
    transform: scaleX(1) scaleY(1);
    background-color: #d8ea7a; } }

@-webkit-keyframes btnAnimationFrames {
  0% {
    -webkit-transform: scaleX(1) scaleY(1);
    background-color: #d8ea7a; }
  50% {
    -webkit-transform: scaleX(1.05) scaleY(1.05);
    background-color: #b1e60e; }
  100% {
    -webkit-transform: scaleX(1) scaleY(1);
    background-color: #d8ea7a; } }
