

.lp {
    font-family: "Verdana", "Roboto", sans-serif;
}

.w-bg {
    display: inline-block;
    padding: 0 15px;
    margin-bottom: 5px;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.92);
}

.b-shadow {
    box-shadow: 0 2px 7px 0 rgba(78, 113, 8, 0.59);
}

.lp-header {

    .container {
        display: flex;
        align-items: center;
        min-height: 100vh;
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 65%;
            height: 100%;
            // border-left: 10px solid white;
            // border-bottom: 10px solid white;
            border-bottom-left-radius: 100%;
            background-image: url(../images/bglp.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            background-position:  0 100%;
            -webkit-box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.4);
            box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.4);
            z-index: 1;
        }
    }

    &__content {
        position: relative;
        max-width: 780px;
        padding: 40px 50px;
        z-index: 3;
    }

    &__title {
        font-weight: 800;
        font-family: "Montserrat", sans-serif;
        font-size: rem(48);
        line-height: 1.2;
        color: $dark-green;
        margin-bottom: 40px;

    }

    &__list {
        max-width: 420px;
        margin-bottom: rem(50);
        padding: 15px 15px 15px 30px;
        border-radius: 12px;
        background-color: rgba(255, 255, 255, 0.92);
    }

    &__item {
        position: relative;
        font-size: rem(17);
        font-weight: 500;
        line-height: 1.4;
        padding-left: 10px;
        margin-bottom: 12px;
        list-style-type: square;
    }

    &__watchword {
        font-weight: 800;
        font-family: "Montserrat", sans-serif;
        font-size: rem(30);
        font-weight: 700;
        margin-bottom: 20px;
        line-height: 1.2;
        
    }
}

.phone-list {

    margin-left: 60px;

    &__item {
        display: inline-block;
        line-height: 2;

        &:not(:last-child) {
            margin-right: 20px;
        }
    }    

    &__link {
        font-weight: 800;
        font-family: "Montserrat", sans-serif;
        font-size: rem(23);
        font-weight: 700;
        color: $dark-green;

        &:hover,
        &:active {
            color: $green;
        }
    }
}

.text_acc {
    color: $dark-green;
    font-weight: 600;
    font-size: rem(20);
}

.promise {
    position: relative;
    padding: 160px 0;

    &:before {
        content: "";
        display: block;
        position: absolute;
        left: 2%;
        top: -5%;
        width: 600px;
        height: 600px;
        background-image: url(../images/lp/house_icon.svg);
        background-size: contain;
        background-repeat: no-repeat;
        z-index: -1;
        opacity: .05;
    }

    &__title {
        @extend .lp-header__watchword;
        text-align: center;
        margin-bottom: 50px;
        font-size: rem(35);
        line-height: 1.2;
    }

    &__list {
        width: 60%;
        margin: auto;
    }

    &__item {
        font-size: rem(17);
        font-weight: 400;
        line-height: 1.8;
        padding-bottom: 28px;

        &:first-child .text_acc {
            font-size: 28px;
        }

    }

    &__wrap {
        // width: 70%;
        padding: 50px 60px;
        margin: 180px auto 0;

        background-color: $light-green;

        -webkit-box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.4);
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.4);
        position: relative;

        &:before {
            content: '';
            position: absolute;
            display: block;
            width: 300px;
            height: 300px;
            top: 50%;
            left: 2%;
            transform: translateY(-50%);
            border-radius: 50%;
            border: 10px solid #fff;
            background-image: url(../images/lp/builder.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            -webkit-box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.4);
            box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.4);

        }
        
    }

    &__message {
        font-family: "Montserrat", sans-serif;
        font-size: rem(22);
        margin-bottom: 15px;
        font-weight: 700;
        line-height: 1.6;
        padding-left: 35%;
    }

}

.how-it-works {
    padding: 120px 60px;
    background-color: #efefef;
    -webkit-box-shadow: inset 0 2px 7px 0 rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 2px 7px 0 rgba(0, 0, 0, 0.2);
    background-color: #efefef;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23cccccc' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");

    &__title {
        @extend .lp-header__title;
        // font-size: 35px;
        text-align: center;
        margin-bottom: 60px;
        // color: $dark-green;
    }

    &__list {
        margin-bottom: 100px;
        display: flex;
        flex-flow: row wrap;
        counter-reset: li;
        width: 80%;
        margin: 0 auto;
    }

    &__item {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 55px;

        &:nth-child(even) {
            margin-left: auto;
        }

        &:before {
            // display: block;
            position: absolute;
            counter-increment: li;
            content: "0" counter(li) " ";
            font: 800 80px "Montserrat", sans-serif;
            color: $green;
            top: -60px;
            left: -42px;
        }
    }

    &__img {
        max-width: 340px;
        margin-right: 60px;
    }

    &__text {
        font-weight: 600;
        font-size: rem(18);
        line-height: 1.4;
        max-width: 400px;
    }

    &__message {
        @extend .lp-header__watchword;
        text-align: center;
    }

    .phone-list {
        text-align: center;
        margin: 0;
    }
}

.lp-offer {
    display: flex;
    flex-flow: row wrap;
    padding: rem(240) rem(60) rem(100);

    &__left {
        width: 55%;
        padding: 0 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__right {
        position: relative;
        // padding: 0 0 0 60px; 
        width: calc(45% - 60px);
    }

    &__center {
        width: 100%;
        padding: 180px 0;
    }

    &__message {
        font-weight: 600;
        font-size: rem(24);
        line-height: 28px;
        margin-bottom: 35px;
        max-width: 470px;
    }

    &__offer {
        font-style: italic;
        font-weight: 500;
        max-width: 415px;
        line-height: 20px;
        margin-bottom: 25px;
        margin-left: 60px;
    }

    .promo__save {
        font: 600 14px Verdana, serif;
        color: $dark-green;
        margin-left: 60px;

        i:first-child {
            margin-right: 7px;
            font-size: rem(18);

        }

        i:last-child {
            margin-left: 10px;
        }

        &:hover i:last-child {
            margin-left: 20px;
        }
    }

    &__subtitle {
        font: 900 35px "Montserrat", sans-serif;
        margin-left: 60px;
        margin-bottom: 50px;
    }

    &__list {
        margin: 0 auto 140px;
        max-width: 750px;
        
    }

    &__item {
        font-family: "Montserrat", sans-serif;
        font-size: rem(18);
        font-weight: 500;
        line-height: 1.5;
        padding-left: 30px;
        margin-bottom: 30px;
        list-style-type: decimal;
        position: relative;

        &:before {
            content: " ";
            display: block;
            position: absolute;
            width: 40px;
            height: 40px;
            background-color: $light-green;
            border-radius: 50%;
            left: -36px;
            top: -10px;
            z-index: -1;
        }
    }

    &__spec {
        font: 800 rem(22) "Montserrat", sans-serif;
        background-color: $black;
        color: white;
        padding: rem(15) rem(40);
        border-radius: 12px;

        &-wrap {
            position: absolute;
            top: -83px;
            left: -59px;
        }

        &-price {
            font: 900 rem(38) "Montserrat", sans-serif;
            background-color: $dark-green;
            color: white;
            padding: rem(10) rem(18);
            border-radius: 12px; 
            position: absolute;
            right: -35px;
            top: 45px;
        }

        &-img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    &__watchword {
        @extend .how-it-works__message;
    }

    .phone-list {
        text-align: center;
        margin-left: 0;
    }
}

@media screen and (max-width: 1180px) {
    .how-it-works__list {
        width: 100%;
    }
}

@media screen and (max-width: 1024px) {

    .lp-header {

        .container:before {
            width: 80%;
        }

        &__list {
            -webkit-box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.4);
            box-shadow: 0 2px 7px 0 rgba(78, 113, 8, 0.59);
            
        }

        &__item {
            font-weight: 600; 
            font-size: rem(14);
        }
    }

    .promise__wrap:before {
        width: 250px;
        height: 250px;
    }

    .lp-offer {
        padding: rem(200) rem(20) rem(100);
    }
}

@media screen and (max-width: 800px) {

    .lp-header {
        &__content {
            padding: 100px 0;
        }

        &__title {
            font-size: 2.5rem;
        }
    }
    .promise__wrap:before {
        position: relative;
        width: 250px;
        height: 250px;
        margin-bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
    }

    .promise__message {
        padding-left: 0;
    }

    .promise__title {
        max-width: 90%;
    }

    .lp-offer {
        justify-content: center;

        &__list {
            margin-left: 40px;
        }
    }

    .lp-offer__left {
        width: 100%;
        padding: 0 0 150px;
    }

    .lp-offer__right {
        width: 60%;
    }


}

@media screen and (max-width: 639px) {

    .lp-header {

        .container:before {
            width: 100%;
            height: 100vh;
            opacity: 0.7;
            border-radius: 0;
            background-position: center center;

        }

        &__content {
            padding: 100px 0;
        }

        &__title {
            font-size: 2rem;
        }

        &__list {
            padding: 15px 15px 15px 30px;
            border-radius: 12px;
            background-color: rgba(255, 255, 255, 0.92);
            -webkit-box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.4);
            box-shadow: 0 2px 7px 0 rgba(78, 113, 8, 0.59);
            // margin-left: 0;
            
        }

        &__item {
           font-weight: 600; 
           font-size: rem(14);
        }
    }

    .promise {
        // overflow: hidden;

        &:before {
            left: auto;
            right: 0;
            width: 300px;
            background-size: cover;
        }

        &__title {
            font-size: rem(28);
            padding: 0 20px;
        }

        &__list {
            width: 100%;
            padding: 0 20px;
        }

    
        &__wrap {
            padding: 60px 20px;
        }

        &__wrap:before {
            position: relative;
            width: 250px;
            height: 250px;
            margin-bottom: 40px;
            left: 50%;
            transform: translateX(-50%);
        }

        &__message {
            padding: 0;
            font-size: rem(16);
        }
    }

    .how-it-works {
        padding: 80px 20px;

        &__list {
            width: 100%;
        }

        &__item {
            flex-flow: column wrap;
            margin-bottom: 85px;
        }

        &__img {
            max-width: 100%;
            margin-bottom: 25px;
            margin-right: 0;
        } 
    }

    .phone-list {

        &__item {

            &:not(:last-child) {
              margin-right: 0;  
            }
            
        }
    }

    .lp-offer {
        padding: 120px 20px;
        flex-direction: column;

        &__left,
        &__right {
            width: 100%;
            padding: 0 0 150px;

        }

        &__offer {
            margin-left: 30px;
        }

        .promo__save {
            margin-left: 30px;
        }

        &__spec-wrap {
            left: -15px;
            right: -15px;
            top: -90px
        }

        &__spec {
            padding: rem(10) rem(15) rem(35);

            &-price {
                font-size: rem(30);
                right: -3px;
                top: 63px;
            }
        }

        &__center {
            padding: 0;
        }

        &__subtitle {
            font-size: rem(30);
            margin-left: 0;
        }

        &__list {
            padding-left: 10px;
        }

        &__item {
            padding-left: 0px;
        }
    }
}