/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 20, 2018 */

@font-face {
    font-family: '20_db';
    src: url('../fonts/../fonts/20db-webfont.woff2') format('woff2'),
         url('../fonts/../fonts/20db-webfont.woff') format('woff'),
         url('../fonts/../fonts/20db-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-black-webfont.woff2') format('woff2'),
         url('../fonts/lato-black-webfont.woff') format('woff'),
         url('../fonts/lato-black-webfont.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-blackitalic-webfont.woff2') format('woff2'),
         url('../fonts/lato-blackitalic-webfont.woff') format('woff'),
         url('../fonts/lato-blackitalic-webfont.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-bold-webfont.woff2') format('woff2'),
         url('../fonts/lato-bold-webfont.woff') format('woff'),
         url('../fonts/lato-bold-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/lato-bolditalic-webfont.woff') format('woff'),
         url('../fonts/lato-bolditalic-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-hairline-webfont.woff2') format('woff2'),
         url('../fonts/lato-hairline-webfont.woff') format('woff'),
         url('../fonts/lato-hairline-webfont.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-hairlineitalic-webfont.woff2') format('woff2'),
         url('../fonts/lato-hairlineitalic-webfont.woff') format('woff'),
         url('../fonts/lato-hairlineitalic-webfont.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-italic-webfont.woff2') format('woff2'),
         url('../fonts/lato-italic-webfont.woff') format('woff'),
         url('../fonts/lato-italic-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-light-webfont.woff2') format('woff2'),
         url('../fonts/lato-light-webfont.woff') format('woff'),
         url('../fonts/lato-light-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-lightitalic-webfont.woff2') format('woff2'),
         url('../fonts/lato-lightitalic-webfont.woff') format('woff'),
         url('../fonts/lato-lightitalic-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/lato-regular-webfont.woff2') format('woff2'),
         url('../fonts/lato-regular-webfont.woff') format('woff'),
         url('../fonts/lato-regular-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Roboto:100,300,400,500,700,900|Ubuntu:300,400,500,700&display=swap&subset=cyrillic,cyrillic-ext');