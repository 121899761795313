.blog {
  display: flex;
  flex-flow: row wrap;
  padding: 0px 60px 80px;
  box-shadow: inset 0 2px 7px 0 rgba(0, 0, 0, 0.2);

  &-posts {
    width: 70%;
    padding-top: 40px;
    padding-right: 30px;
  }

  .post-sidebar {
    background-color: #eee;
  }
}

.post {
  display: flex;
  max-width: 800px;
  padding: 20px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }

  &__wrap-img {
    width: 30%;
    margin-right: 20px;
    flex-shrink: 0;
  }

  &__img {
    display: block;
    width: 100%;
    height: auto;
  }

  &__content {
    max-width: 600px;
    flex-grow: 1;
  }

  &__title {
    font: 600 rem(18) "Lato", Verdana, sans-serif;
    margin-bottom: 15px;

    &.new a {
      display: inline-block;
      background-color: $green;
      padding: 5px 12px;
      border-radius: 7px;
      position: relative;

      &::after {
        content: "новинка";
        position: absolute;
        right: -45px;
        top: -13px;
        padding: 3px 7px 3px;
        border-radius: 5px;
        font-size: 10px;
        text-transform: uppercase;
        color: white;
        background-color: red;

        @media screen and (max-width: 480px) {
          right: -15px;
        }
      }
    }
  }

  p {
    font-size: rem(16);
    line-height: 1.6;
  }

  &__read-more {
    font-weight: 500;
    color: $dark-green;

    &:hover,
    &:visited {
      color: $green;
    }
  }

  &_prev {
    .post {
      padding: 0;
      margin-bottom: 20px;
      flex-flow: column wrap;
    }

    .post__title {
      font-size: 13px;
    }

    .post__wrap-img {
      width: 100%;
      margin-bottom: 10px;
      margin-right: 0px;
    }

    .post__read-more {
      font-style: italic;
    }

    .post__text {
      display: none;
    }
  }
}

.post-page {
  padding: 80px 60px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .post-page__body > h2 {
    margin-top: 35px;
    margin-bottom: 20px;
    font-size: rem(18);
    line-height: 1.4;
  }

  &__body {
    width: 70%;
    padding-right: 60px;

    .post-page__subtitle {
      font-size: rem(25);
      font-weight: 700;
      margin-bottom: 30px;
      line-height: 1.3;
    }

    ul {
      margin-bottom: rem(30);
      font-size: rem(17);
      padding-left: rem(30);
      line-height: 1.5;

      li {
        list-style-type: square;
        margin-bottom: rem(15);
        padding-left: rem(13);
      }
    }

    ol {
      margin-bottom: rem(30);
      font-size: rem(17);
      padding-left: rem(30);
      line-height: 1.5;

      li {
        list-style-type: decimal;
        margin-bottom: 15px;
        padding-left: 13px;
      }
    }

    img {
      display: inline-block;
      max-width: 100%;
      margin-bottom: 15px;
    }

    figure {
      text-align: center;
      padding: rem(35) 0 rem(50);
    }

    figcuption {
      display: block;
    }
  }

  &__title {
    font-size: rem(35);
    line-height: 1.2;
    margin-bottom: rem(40);
    font-family: "Montserrat", sans-serif;
  }

  p {
    font-size: rem(17);
    line-height: 1.6;
    color: #222;
    margin-bottom: 20px;
    text-align: justify;
  }

  &__image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: center;
  }
}

.post-slider {
  padding: 60px 0;

  &__item {
    width: 100%;
    height: auto;
  }

  &__photo {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  font-weight: 700;

  a:hover {
    color: $dark-green;
  }

  .fa-arrow-left {
    margin-right: 10px;
  }

  .fa-arrow-right {
    margin-left: 10px;
  }
}

.post-sidebar {
  width: 30%;
  padding-top: 40px;
  padding-right: 20px;

  &__title {
    font: 700 18px "Montserrat", sans-serif;
    margin-left: 25px;
    margin-bottom: 25px;
    display: inline-block;
    background-color: $dark-green;
    color: white;
    padding: 5px 12px;
    border-radius: 7px;
  }

  &__list {
    border-left: 1px solid #eee;
    padding-left: 20px;
    margin-bottom: 40px;
  }
}

.promotion-build {
  display: block;
  padding-left: 20px;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 1180px) {
  .page-title__bg {
    font-size: 4rem;
  }
}

@media screen and (max-width: 1023px) {
  .page-title__bg {
    padding: 40px 6px;
    margin-bottom: 0px;
    margin-top: 80px;
    font-size: 3rem;
  }
}

@media screen and (max-width: 800px) {
  .blog {
    padding-left: 0px;
    padding-right: 0px;
  }
  .blog-posts {
    width: 100%;
    padding-left: 30px;
    padding-right: 60px;
    padding-bottom: 60px;
  }

  .post-sidebar {
    width: 100%;
    border-top: 1px solid #eee;
    margin-top: 40px;

    &__list {
      display: flex;
      justify-content: flex-start;
      flex-flow: row wrap;
      border-bottom: 1px solid #eee;
      border-left: none;
    }

    &__item {
      width: 45%;
      margin-right: 20px;
    }
  }

  .post-page__body {
    width: 100%;
    padding-right: 0px;
  }
}

@media screen and (max-width: 639px) {
  .page-title__bg {
    font-size: 32px;
  }

  .blog-posts {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 60px;
  }

  .post {
    flex-flow: row wrap;
    &__wrap-img {
      width: 100%;
      margin-bottom: 20px;
    }

    &-sidebar__item {
      width: 100%;
      margin-right: 10px;
    }
  }

  .post_prev .post__title {
    font-size: 11px;
  }

  .post-page {
    padding: 50px rem(30);

    &__title {
      font-size: rem(28);
    }
  }

  .post-prev {
    width: 100%;
  }

  .post-next {
    margin-left: auto;
  }
}
