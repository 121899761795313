.dom-gallery {
  background: url(../images/concrete_seamless.png) #f2f0f0 repeat;
  padding-bottom: 60px;
  box-shadow: inset 0px 0px 13px 2px rgba(0, 0, 0, 0.25);
}

.dom-page-wrap {
  position: absolute;
  top: 100px;
  z-index: 3;
}

.dom-page-title {
  font: 900 1.8rem $title-font;
  text-transform: uppercase;
  display: inline-block;
  padding: 10px 40px;
  background: rgba(177, 230, 14, 1);
  color: #fff;
  margin-left: 60px;
}

.icon-house {
  background: $gray;
  display: block;
  height: 55px;
  margin-left: 20px;
  margin-top: 55px;
  position: absolute;
  left: 6px;
  top: -62px;
  width: 100px;
  z-index: -1;
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  &:before {
    border-bottom: 35px solid $gray;
    border-left: 49px solid transparent;
    border-right: 49px solid transparent;
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    top: -35px;
    width: 0;
  }
}

.house-info {
  display: flex;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  border: 1px solid #fefefe;

  &__item {
    display: flex;
    flex-wrap: wrap;
    // flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 135px;
    height: 135px;
    background-color: rgba(45, 45, 45, 0.8);
    border: 1px solid #fefefe;
    text-align: center;
    font-family: "Play", sans-serif;
    padding: 10px 5px;

    &__icon {
      display: inline-block;
      width: 35px;
      height: 35px;
      background-repeat: no-repeat;
      background-position: left bottom;
      background-size: cover;
    }

    &__title {
      display: block;
      width: 100%;
      font-size: 13px;
      font-weight: 600;
      color: $white;
    }

    &__amount {
      display: block;
      width: 100%;
      font-size: 18px;
      font-weight: 600;
      color: $light-green;
    }
  }
}

.img-hover-anim {
  position: relative;

  &:after {
    font-family: "Font Awesome 5 Free";
    content: "\f002";
    cursor: pointer;
    color: $white;
    font-size: 2rem;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(137, 223, 4, 0.5);
    opacity: 0;
    transition: all 0.2s ease-in-out;
    border-radius: 3px;
    z-index: 2;
  }

  &:hover:after {
    opacity: 1;
  }

  &.main_photo:after {
    background: rgba(2, 2, 2, 0.05);
    color: transparent;
    opacity: 1;
  }

  &.white:after {
    color: $green;
    background-color: rgba(255, 255, 255, 0.5);
  }

  &:hover .dom_img-plan {
    border: 2px solid $green;
  }
}

.dom-content__scheme {
  margin-top: 90px;
}

.dom-content__scheme_first-house {
  margin-top: 40px;
}

.dom-content__scheme_third-house {
  margin-top: 250px;
}

.dom-content__header {
  font: 600 2rem "Lato", Arial, sans-serif;
  padding: 35px 0 25px;
}

.dom-content__header_scheme {
  margin-bottom: 20px;
  padding-top: 0;
}

.dom-content__header_price {
  padding: 10px;
}

.flexbox-cl {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.header__dom {
  background: linear-gradient(to bottom, #1e1e1e 0%, #393939 100%);
}

.dom-table_column {
  padding-bottom: 10px;
}

.dom-table_column_info {
  line-height: 1.4rem;
}

.dom-content__header_price {
  font: 800 1.7rem "Lato", Verdana, sans-serif;
}

.dom__video {
  min-height: 424px;
  padding-top: 50px;
  background: #000;
  display: flex;
  width: 100%;
  height: auto;
}

.dom_details-page {
  background: url(../images/concrete_seamless.png) #f2f0f0 repeat;
}

.dom-photo {
  justify-content: space-around;

  &_item {
    // display: flex;
    width: 30%;
    height: auto;
    margin-bottom: 20px;

    &.main_photo {
      width: 100%;
      margin-bottom: 60px;
    }
  }
}

.pad-block {
  top: 40px;
}

.dom-plan {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px 50px 60px;

  .dom-photo_item {
    width: 100%;
  }
}
.dom_img {
  display: block;
  width: 100%;
  // height: 100%;
  cursor: pointer;
  position: relative;
}

.dom_img-plan {
  padding: 20px;
  border: 2px solid transparent;
  display: block;
  margin: 0 auto;
  cursor: pointer;
  max-height: 420px;
}

.dom-content {
  width: 40%;
  padding: 50px 50px 40px;
  z-index: 3;
}

.dom-title {
  border-bottom: 1px solid #b7b7b7;
  padding: 25px 0;
  display: flex;
  flex-flow: nowrap column;
  align-items: flex-start;
  margin-top: 20px;
}

.dom-title__header {
  margin-bottom: 20px;
}

.dom__text,
.dom-table {
  font: 1.2rem $alt-font;
  color: #000;
  line-height: 2rem;
  padding: 10px 0;
}

.dom__text-bold {
  font-weight: 800;
}

.dom-table {
  width: 100%;
}

.dom-table_column {
  width: 30%;
  font-size: 1.1rem;
}

.dom-carousel_item {
  width: 25%;
  cursor: pointer;
}

.dom-carousel_item__img {
  width: 100%;
  height: 100%;
}

.dom__text_link {
  color: grey;
  padding-bottom: 0;
  border-bottom: 1px dashed grey;
  font-size: 1.1rem;
  cursor: pointer;
}

.dom__text_link:hover {
  color: #000;
}

.dom__additinal-text {
  padding: 0 0 0 38px;
  position: relative;
  font: 1.1rem $alt-font;
  margin-bottom: 10px;
  width: calc(100% + 10px);
}

.dom__additinal-text:before {
  font-family: FontAwesome;
  content: "\f111";
  font-size: 0.45rem;
  position: absolute;
  top: 40%;
  left: 0;
}

.table-column {
  padding: 0 20px;

  .align {
    font-weight: bold;
  }
}

.table-row {
  background-color: $white;

  &:nth-child(even) {
    background-color: #efefef;
  }
}

.scheme__title {
  background: $green;
  font-family: $alt-font;
  font-size: 1.4rem;
  color: white;
  padding: 10px 20px;
  margin: 20px 0 10px;
}

.carousel_item_resize {
  height: 153px;
  width: auto;
}

.dom-live_1 {
  background: url(../images/dom-live_1.jpg) no-repeat center center/cover;
  width: 100%;
  height: 100%;
}

.gray {
  background-color: #cccccc;
}

.light-gray {
  background-color: #ebebeb;
}

.plan-table {
  width: 100%;
  margin-bottom: 60px;
}

.align {
  text-align: right;
}

.dom-title__header {
  font: 600 1.8rem "Lato", Arial, sans-serif;
}

.dom-carousel {
  height: auto;
  width: 95%;
  margin: 0 auto;

  .slick-slider {
    padding: 40px 0;
    margin: auto;
  }

  .slick-slide {
    margin-right: 20px;
  }
}

.interior {
  background-image: url(../images/white-tiles.png);
  background-repeat: repeat;
  background-color: #d8ea7a;
  padding: 40px 0 80px;
  text-align: center;

  &-slider {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 80px 0 30px;

    &_item {
      margin: 10px;
      height: 200px;
      cursor: pointer;
    }
  }

  &-photo {
    height: 100%;
    width: auto;
    max-width: 100%;
    object-fit: cover;
  }
}

.finished-house {
  @extend .interior;
  background: rgba(45, 45, 45, 0.8);

  &-slider {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 80px 0 30px;
    max-width: 95%;

    &_item {
      margin: 10px;
      height: 200px;
      cursor: pointer;
    }
  }

  &-photo {
    height: 100%;
    width: auto;
  }
}

.btn-gallery {
  background-color: $light-green;
  color: $gray;

  &:hover {
    background: $green;
    color: $white;
  }
}

.dom-review {
  display: flex;
  width: 100%;
  background: url(../images/concrete_seamless.png) #f2f0f0 repeat;
  padding-bottom: 80px;
  box-shadow: inset 0px 0px 13px 2px rgba(0, 0, 0, 0.25);

  &-wrap {
    display: flex;
    width: 100%;
    box-shadow: 0px 0px 13px 2px rgba(0, 0, 0, 0.25);
  }
}

.dom-description {
  width: 100%;
  max-width: 960px;
  background-color: white;
  font-size: 1.18rem;
  line-height: 1.5em;
  padding: 50px;

  .bavarskiy_link {
    font-weight: 600;
    color: $green;
    transition: color 0.3s;

    &:hover {
      color: $light-green;
      transition: color 0.3s;
    }
  }

  p {
    text-align: justify;
    padding: 10px 0;
  }
}

.house-price {
  font-size: 28px;
  font-weight: 600;
  color: #444;
  margin-top: 30px;
}

.panorama {
  font: 300 16px $alt-font;
  position: relative;
  background-color: #333;
  color: #eee;
  padding: 60px 0px 80px;
  overflow: hidden;

  &-container {
    width: 100%;
    max-width: 1000px;
    height: 100%;
    min-height: 500px;
    position: relative;
    margin: 0 auto;
  }
}
