@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800,900&display=swap');

body {
  font-family: $main-font;
  font-weight: 300;
  line-height: 1.4em;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
}

main {
  width: 100%;
  padding-left: 180px;
}

// img {
//   pointer-events: none;
// }

.sunny-plan {
  padding: 60px 0 60px;
  border-top: 1px dashed #efefef;
}

.hidden-title {
  display: none;
}

/*** SLIDER ***/

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.05);
}

.page-title {
  font: 52px 'Russo One', sans-serif;

  &__bg {
    background-color: #7bca03;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23efefef' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
    padding: 40px 60px;
    margin-bottom: 30px;
  }
}

.section-title {
  font: 900 3.8rem $title-font;
  text-transform: uppercase;
  padding-top: 80px;
  text-shadow: 2px -1px 6px rgba(0, 0, 0, 0.9);
  display: inline-block;
}

.section-subtitle {
  font: 600 1.8rem $main-font;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.offers-title {
  font: 600 30px $alt-font;
  color: $white;
}

.page-subtitle {
  font-size: 18px;
  font-weight: 700;
  color: $white;
  padding: 20px 0 60px;
}

.main-slider {
  position: relative;
  width: 100%;
  color: $white;
  overflow: hidden;
}

.main-slide {
  position: relative;
  height: 100vh !important;
  width: 100%;
  overflow: hidden;

  &__content {
    text-align: center;
  }
}

.main-slide__1 {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.25) 0%,
      rgba(0, 0, 0, 0) 12%
    ),
    url(../images/main/main-bg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.slider-contacts {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 51;

  &__item {
    display: inline-block;
    padding: 30px 60px 30px 0;
  }

  &__link {
    font: 600 12px $alt-font;
    text-transform: uppercase;
    &:hover {
      color: $light-green;
    }
  }
}

.btn-slide {
  opacity: 1;
  color: $white;
  font: 300 1.3rem $main-font;
  background-color: rgba(63, 210, 39, 0.9);
  border-radius: 30px;
  padding: 7px 30px 10px;
  box-shadow: -3px 3px 4px 2px rgba(0, 0, 0, 0.17);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: relative;
  width: 224px;
  text-align: center;
  &:hover {
    background-color: #3fd227;
  }
}

#sale {
  // background-color: #a00023;
  background-color: #ccc;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffffff' fill-opacity='0.36' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}
// spec offer
.spec-offer__item {
  width: 100%;
  // min-height: 100vh;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.sp__img {
  display: block;
  object-fit: contain;
  margin: 0 auto;
  // min-height: 100%;
  max-width: 100%;
}

.house-animation__button {
  -webkit-animation: btnAnimationFrames ease-in-out 2s;
  animation: btnAnimationFrames ease-in-out 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards; /*when the spec is finished*/
  -webkit-animation: btnAnimationFrames ease-in-out 3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
  -moz-animation: btnAnimationFrames ease-in-out 2s;
  -moz-animation-iteration-count: infinite;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode: forwards; /*FF 5+*/
  -o-animation: btnAnimationFrames ease-in-out 2s;
  -o-animation-iteration-count: infinite;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode: forwards; /*Not implemented yet*/
  -ms-animation: btnAnimationFrames ease-in-out 2s;
  -ms-animation-iteration-count: infinite;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode: forwards; /*IE 10+*/
  overflow: hidden;

  &:hover {
    animation: none;
  }
}

@keyframes animationFrames {
  0% {
    -webkit-transform: scaleX(1) scaleY(1);
    transform: scaleX(1) scaleY(1);
  }
  50% {
    -webkit-transform: scaleX(1.05) scaleY(1.05);
    transform: scaleX(1.05) scaleY(1.05);
  }
  100% {
    -webkit-transform: scaleX(1) scaleY(1);
    transform: scaleX(1) scaleY(1);
  }
}

@-webkit-keyframes animationFrames {
  0% {
    -webkit-transform: scaleX(1) scaleY(1);
  }
  50% {
    -webkit-transform: scaleX(1.05) scaleY(1.05);
  }
  100% {
    -webkit-transform: scaleX(1) scaleY(1);
  }
}

@keyframes btnAnimationFrames {
  0% {
    -webkit-transform: scaleX(1) scaleY(1);
    transform: scaleX(1) scaleY(1);
  }
  50% {
    -webkit-transform: scaleX(1.05) scaleY(1.05);
    transform: scaleX(1.05) scaleY(1.05);
  }
  100% {
    -webkit-transform: scaleX(1) scaleY(1);
    transform: scaleX(1) scaleY(1);
  }
}

@-webkit-keyframes btnAnimationFrames {
  0% {
    -webkit-transform: scaleX(1) scaleY(1);
  }
  50% {
    -webkit-transform: scaleX(1.05) scaleY(1.05);
  }
  100% {
    -webkit-transform: scaleX(1) scaleY(1);
  }
}

#sale .spec-btn {
  position: absolute;
  left: 50%;
  bottom: 60px;
  margin-left: -112px;
  background: rgba(219, 5, 21, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.8);
}

.offers_sale:before {
  // width: 120px;
  // height: 120px;
  // top: 0;
  // left: -1px;
  // content: '';
  // position: absolute;
  // display: block;
  // z-index: 5;
  // background: url(../images/sale2.svg);
  // background-repeat: no-repeat;
  // background-size: contain;
  // content: 'продажа';
  // 	display: block;
  // 	box-sizing: border-box;
  // 	line-height: 36px;
  // 	padding-left: 15px;
  // 	padding-right: 15px;
  // 	position: absolute;
  // 	top: 5px;
  // 	left: 0;
  //   background-color: $green;
  //   opacity: .8;
  // 	font-size: 20px;
  // 	font-weight: 600;
  // 	color: white;
  // 	z-index: 4;
}
