.pop-up {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  overflow: hidden;
  z-index: 99;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  pointer-events: none;
  opacity: 0;
  transition: all 0.7s ease-in-out;

  &_wrap {
    display: flex;
    min-height: 300px;
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-family: "Play", Arial, sans-serif;
    color: $white;
    text-shadow: none;
    font-size: 2.3rem;
    padding-bottom: 40px;
  }
}

.pop-up-container {
  width: 60%;
  max-width: 400px;
  min-width: 320px;
  position: relative;
  text-align: center;
  padding: 40px 0;
  background-image: url(../images/lifebud-logo-bg.svg);
  background-repeat: no-repeat;
  background-position: -110% 110%;
  background-size: 120%;
  background-color: $gray;
  border-top: 5px solid $green;

  textarea {
    height: 200px;
    resize: none;
  }
}

.thx-msg {
  color: #fbf6f6;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  background-color: #2d2d2deb;
  padding: 50px 30px 60px;
  margin: 0px 20px;
  border: 6px solid $green;
}

.pop-up-title {
  text-align: center;
}

.fa-times-circle {
  position: absolute;
  cursor: pointer;
  color: $green;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;

  &:hover {
    color: $light-green;
  }

  &:before {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 30px;
  }
}

.message-title {
  font: 800 40px "Play", Arial, sans-serif;
  color: #d8ea7a;
  padding: 15px 0;
}

.message {
  display: none;
  font: 800 1rem "Lato", Arial, sans-serif;
  color: #ff0101;
  // opacity: 0;
  padding-top: 20px;
}

#contacts .message {
  color: #fbf6f6;
}

.message-text {
  font: 20px "Lato", Arial, sans-serif;
  color: #b1e60e;
  width: 80%;
  margin: 0 auto;
}

.pop-up__textfield {
  display: inline-block;
  width: 70%;
  background-color: rgba(255, 255, 255, 0.95);
  font: 1.4rem "Play", Arial, sans-serif;
  color: $gray;
  padding: 10px 25px;
  margin-bottom: 15px;

  &:focus {
    color: #000;
  }

  &:active {
    color: #000;
  }
}

.pop-up__textfield::placeholder {
  color: #cfcfcf;
}

.textfield:focus,
.pop-up__textfield:focus {
  color: #030303;
}

.textfield:active,
.pop-up__textfield:active {
  color: #030303;
}

.callback-form {
  background: fixed url(../images/form-bg.jpg) no-repeat top center/cover;
  padding: 90px 0 70px;
  text-align: center;
  box-shadow: inset 0px 0px 13px 2px rgba(0, 0, 0, 0.35);
}

input:invalid {
  background: #fdd;
}

.show {
  opacity: 1;
  pointer-events: auto;
}

.btn-submit {
  color: #575759;
  background: #b1e60e;
  margin: 40px auto 0;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: $gray;
  }
}

.btn-submit:disabled {
  opacity: 0.5;
}

.btn-submit:hover {
  background-color: #d8ea7a;
}

.pop-up-message {
  display: none;
}

.pop-up-message.show {
  display: none;
}

.pop-up-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 400px;
  min-height: 350px;
  padding: 30px 15px 30px;
  background-size: 150px;
  background-repeat: no-repeat;
  background-color: #434345;
  background-image: url(../images/lifebud-logo-bg.svg);
  background-position: right 20px bottom 30px;
  border-top: 5px solid #89df04;
  text-align: center;
  z-index: 155;
  color: $light-green;

  & .fa-check-square {
    margin-bottom: 30px;

    &:before {
      color: rgba(177, 230, 14, 1);
      font-size: 40px;
    }
  }

  & .pop-up__title {
    font-weight: 700;
    margin-bottom: 20px;
  }
}
