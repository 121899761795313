/* FONTS */

// $title-font: '20_db', sans-serif;
//$title-font: 'Russo One', sans-serif;
$title-font: "Montserrat", sans-serif;
$alt-font: "Play", sans-serif;
$main-font: "Roboto", sans-serif;

/* COLORS */

$black: #2d2d2d;
$white: #fff;
$green: #89df04;
$light-green: #d8ea7a;
$gray: #434345;
$light-gray: #575759;
$dark-green: #7bca03;

// inspired by https://medium.freecodecamp.org/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
// see the article to discuss better breakpoints
@mixin for-size($range) {
  $sm-upper-boundary: 768px;
  $medium-upper-boundary: 1024px;
  $decent-upper-boundary: 1440px;

  @if $range == small {
    @media (max-width: #{$sm-upper-boundary - 1}) {
      @content;
    }
  } @else if $range == medium-down {
    @media (max-width: #{$medium-upper-boundary - 1}) {
      @content;
    }
  } @else if $range == decent-down {
    @media (max-width: #{$decent-upper-boundary - 1}) {
      @content;
    }
  } @else if $range == medium {
    @media (max-width: #{$medium-upper-boundary - 1}) {
      @content;
    }
  } @else if $range == decent {
    @media (max-width: #{$decent-upper-boundary - 1}) {
      @content;
    }
  } @else if $range == mobile {
    @media (max-width: #{$sm-upper-boundary - 1}) {
      @content;
    }
  }
}

@function rem($px) {
  @return ($px / 16) + rem;
}
