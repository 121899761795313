.gallery {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	background: rgba(45, 45, 45, 0.8);
	padding: 120px 30px;
	margin: 0 auto;

	&_item {
		display: block;
    	box-sizing: border-box;
		width: 33%;
		padding: 5px 10px;
		cursor: pointer;
		visibility: hidden;
	}

	&_image {
		width: 100%;
	}
}