.invest-sunny {
  background-image: url(../images/main/sunny-streen-3.jpeg);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  min-height: 100vh;
  padding: 60px 60px;
  justify-content: space-evenly;

  .title-box {
    width: 100%;
    max-width: 855px;
    position: relative;

    h2 {
      font: 800 rem(38) 'Montserrat', sans-serif;
      margin-bottom: rem(50);
      line-height: rem(55);
    }

    p {
      position: relative;
      font: 800 rem(28) 'Montserrat', sans-serif;
      margin-bottom: rem(-25);
      margin-left: 20%;
      line-height: rem(55);

      span {
        position: relative;
        padding: 0 40px 0 20px;
        background: $black;
        color: $white;

        &:before {
          content: ' ';
          display: block;
          position: absolute;
          width: 60px;
          height: 60px;
          right: -30px;
          top: -30px;
          background-color: rgba(255, 255, 255, 0.9);
          background-image: url(../images/house-love.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 60%;
          border-radius: 50%;
          box-shadow: 0 2px 7px 0 rgba(78, 113, 8, 0.59);
        }
      }
    }
  }

  .g-bg {
    background-color: $dark-green;
    color: white;
  }

  .content-box {
    width: 100%;
    max-width: 630px;
    padding: 60px 40px;
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px solid rgba(255, 255, 255, 0.9);
    line-height: rem(36);
    font: 700 rem(20) 'Montserrat', sans-serif;
    border-radius: 12px;
    box-shadow: 0 2px 7px 0 rgba(78, 113, 8, 0.59);

    p,
    li {
      margin-bottom: rem(20);
      line-height: rem(34);
      color: #2c2c2c;
    }

    a,
    span {
      font-weight: 900;
      color: $green;
      white-space: nowrap;
      font-size: 23px;
      vertical-align: top;
    }
  }
}

.promo {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #a00000;
  background-image: url('../images/bg-spring.jpg');
  background-size: cover;
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(13, 10, 10, 0.07);
  }

  &-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 60px 30px 0;
  }

  &_header {
    text-align: center;
  }

  &__item {
    width: 58%;
    position: relative;
    border-radius: 0 100px;
    border: 3px solid white;
    overflow: hidden;
    margin-bottom: 40px;
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;
  }

  &_title {
    color: #de1c1c;
    background: #ffffffde;
    padding: 15px 45px;
    display: inline-block;
    border-radius: 30px;
  }

  &__description {
    font: 400 italic 18px $main-font;
    color: rgb(228, 25, 25);
    text-align: center;
    padding-top: 20px;
  }

  &-terms {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 50px;

    &__desc {
      width: 100%;
      max-width: 1024px;
      background: #ffffff;
      padding: 60px 80px 50px;
      border-radius: 100px;
      margin: 0 auto;
    }

    &-img-wrap {
      width: 39%;
      border-radius: 100px 0 100px;
      overflow: hidden;
      background: $white;
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 15px;
    }

    &__img {
      display: block;
      width: 100%;
      height: auto;
      object-fit: contain;
    }

    p {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 15px;
    }

    ul {
      margin-bottom: 15px;
      li {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 10px;
      }
    }

    .red {
      color: red;
    }

    .promo-slogan {
      font-weight: bold;
    }
  }
}

@media screen and (max-width: 1024px) {
  .promo-terms {
    width: 100%;
    flex-wrap: wrap;
    // flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

    &__desc {
      width: 100%;
      border-radius: 0 100px 0 100px;
      margin-bottom: 40px;
    }

    &-img-wrap {
      width: 100%;
      margin-bottom: 40px;
      max-width: 500px;
      border-radius: 0 100px 0 100px;
    }
  }
}

@media screen and (max-width: 768px) {
  .promo-terms__desc {
    padding: 40px 30px 40px;
  }
}

.promo-description {
  color: red;
  font-style: italic;
  font-weight: 600;
}

.offers-item__content .promo__save {
  font: 600 16px $alt-font;
  color: $gray;

  i {
    font-size: 16px;
    color: #434345;
    padding-left: 10px;
    transform: translateY(1.5px);
    transition: 0.3s;
  }

  &:hover .fa-long-arrow-alt-right {
    transform: translate(10px, 1.5px);
    transition: 0.3s;
  }
}

#pointer {
  width: 270px;
  height: 40px;
  position: absolute;
  top: 40px;
  font: 900 16px $alt-font;
  color: $white;
  background: $green;
  padding: 10px 15px;
  z-index: 2;
}

@media screen and (max-width: 639px) {
  .invest-sunny {
    padding: 30px 5px;
    .content-box {
      padding: 30px 15px;
      font-size: 1.1rem;
    }

    .title-box h2 {
      font-size: rem(28);
      line-height: 1.4;
    }

    .title-box p {
      font-size: rem(25);
      margin-left: 0;
    }
  }
}
