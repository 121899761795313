.invest_table {
  font-size: 12px;
  line-height: 14px;
  margin-top: 40px;
  margin-bottom: 60px;

  .table-column {
    padding: 5px;
  }

  .table-row {
    border: 1px solid $black;
    background: none;

    &:nth-child(even) {
      background: none;
    }
  }

  td {
    border: 1px solid $black;
  }

  thead {
    font-size: 14px;
    font-weight: 700;
    background-color: rgba(255, 255, 255, 0.4);
  }
}

.offers-item__content .red_price {
  font-size: 15px;
  color: #ec0808;
  text-align: right;
  margin-top: 0;
}

.line-through {
  text-decoration: line-through;
}
