footer {
  @extend main;
  // background: url(../images/bg.png) #f2f0f0 repeat;
  background-color: #eceae5;
  background-image: url("data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd'%3E%3Cg id='brick-wall' fill='%23e5d4b9' fill-opacity='0.4'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  padding-top: 35px;
  padding-bottom: 25px;
  position: relative;
  top: -3px;
}

.copyright {
  font: 0.9rem $alt-font;
  color: #c6c5c5;
  text-align: center;
  transition: all 0.2s ease-in-out;

  a:hover {
    color: $green;
  }
}

// .address,
// .call-us {
//   padding-right: 20px;
//   padding-left: 20px;
//   margin-bottom: 20px;
//   display: flex;
//   flex-flow: nowrap column;
//   align-items: center; }

// .address {
//   align-items: left; }

.address__text {
  font: 1.1rem 'Myriad Pro', Verdana, sans-serif;
  color: #fff;
  line-height: 2rem;
}

// .call-us {
//   text-align: right;
//   display: flex;
//   flex-flow: nowrap column;
// }

.call-us .btn-callback {
  margin-top: 10px;
}

.contact-phone {
  font: 1.1rem 'Myriad Pro', Verdana, sans-serif;
  color: #fff;
  transition: all 0.2s ease-in-out;
  line-height: 2rem;
  margin-right: 20px;
}

.contact-phone:last-child {
  margin-bottom: 15px;
}

.contact-phone:hover {
  text-decoration: underline;
}
